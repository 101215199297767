<template>
  <div class="quick-link">
    <div class="quick-title">快捷链接</div>
    <div class="quick-border">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="list"
        @click="golink(item.linkUrl, item.developUrl, item.sid)"
        @mouseleave="changImg(index)"
        @mouseenter="changImg(index)"
      >
        {{ item.title }}
        <img :src="item.iconPathOff" alt="" v-if="item.urlType" />
        <img :src="item.iconPathOn" alt="" v-else />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "quickLink",
  props: {
    linkList: Array,
  },
  data() {
    return {
      data: this.linkList,
      currentEnv: "",
    };
  },
  created() {
    console.log("xxx", this.data, process.env.VUE_APP_ENV);
    if (process.env.VUE_APP_ENV == "DEV" || process.env.VUE_APP_ENV == "SIT") {
      this.currentEnv = "https://sit.rlinkiot.com";
    } else if (process.env.VUE_APP_ENV == "UAT") {
      this.currentEnv = "https://uat.rlinkiot.com";
    } else if (process.env.VUE_APP_ENV == "PROD") {
      this.currentEnv = "https://prod.rlinkiot.com";
    }
  },
  methods: {
    changImg(index) {
      this.data[index].urlType = !this.data[index].urlType;
    },
    golink(linkUrl, developUrl, sid) {
      //判断是否有developUrl，并且是prod环境
      console.log("openUrl", linkUrl, developUrl, sid);
      if (developUrl && process.env.VUE_APP_ENV == "PROD") {
        if (sid) {
          //需要传参即带入参数
          window.open(this.currentEnv + developUrl + "?sid=" + sid, "_blank");
        } else {
          window.open(this.currentEnv + developUrl, "_blank");
        }
      } else if (linkUrl) {
        if (linkUrl.includes("http")) {
          //有http直接打开地址
          window.open(linkUrl, "_blank");
        } else {
          if (sid) {
            //需要传参即带入参数
            window.open(this.currentEnv + linkUrl + "?sid=" + sid, "_blank");
          } else {
            window.open(this.currentEnv + linkUrl, "_blank");
          }
        }
      } else {
        this.$message({
          message: "暂无地址",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.quick-link {
  margin: 40px 0px 16px;
  .quick-title {
    font-size: 20px;
    font-family: Inter-Semi Bold, Inter;
    font-weight: bold;
    color: #000000;
    line-height: 20px;
    margin-bottom: 16px;
  }
  .quick-border {
    display: flex;
    flex-wrap: wrap;
    .list {
      position: relative;
      width: 249px;
      height: 56px;
      line-height: 56px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #ebeef5;
      margin-right: 16px;
      padding-left: 51px;
      cursor: pointer;
      &:hover,
      &:active {
        background: #1a4cec;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #1a4cec;
        color: #ffffff;
      }
      img {
        position: absolute;
        top: 16px;
        left: 16px;
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
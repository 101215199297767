<template>
  <div class="park" v-loading="listLoading">
    <div
      :class="parkIndex == index ? 'park-active park-list' : 'park-list'"
      v-for="(item, index) in parkList"
      :key="index"
      @click="changePark(index, item.projectId, item.rugeProjectId)"
    >
      {{ item.projectName }}
    </div>
  </div>
</template>
<script>
import { getRspaceList } from "@/api/ruge/bms/customerService/financialStatis.js";
export default {
  name: "parkSelection",
  props: {
    linkList: Array,
  },
  data() {
    return {
      listLoading: false,
      parkList: [],
      parkIndex: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    changePark(index, projectId, rugeProjectId) {
      this.parkIndex = index;
      this.$emit("getPark", index, projectId, rugeProjectId);
    },
    getData() {
      this.listLoading = true;
      getRspaceList()
        .then((res) => {
          //根据当前登录租户获取Rspace项目集合
          if (res) {
            this.parkList = res;
            let id = this.parkList[0].projectId;
            let rugeId = this.parkList[0].rugeProjectId;
            this.changePark(0, id, rugeId);
            this.$emit(
              "getAllParks",
              res.map((item) => item.projectId)
            );
          }
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.park {
  display: flex;
  .park-list {
    min-width: 92px;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #e7e8eb;
    font-size: 14px;
    font-family: Noto Sans SC-Regular, Noto Sans SC;
    font-weight: 400;
    color: #2f3941;
    text-align: center;
    margin-right: 8px;
    cursor: pointer;
  }
  .park-active {
    color: #1a4cec;
    border: 1px solid #1a4cec;
  }
}
</style>
import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 *
 *  根据当前登录租户获取Rspace项目集合
 */
export function getRspaceList(params) {
  return request({
    url: envInfo.bgApp.rspacePath + '/tenantToProject/getProjectList',
    method: 'get',
    params,
  });
}

/**
 * 获取账单
 * @param params
 */
export function getLeaseBill(params) {
  let token = localStorage.getItem('application:au');
  return request({
    url: '/api/v3/bill/query/newbillsbysummary?token=' + token,
    method: 'post',
    data: params,
  });
}

/**
 * 获取付款
 * @param params
 */
export function getAdvance(params) {
  let token = localStorage.getItem('application:au');
  return request({
    url: '/api/billing/bank_flow/statistics?token=' + token,
    method: 'get',
    params,
  });
}

/**
 * 已生成/待生成
 * @param params
 */
export function gotGenerated(params) {
  let token = localStorage.getItem('application:au');
  return request({
    url: '/api/billing/payment_notice/list?token=' + token,
    method: 'get',
    params,
  });
}

/**
 * 获取模板id
 * @param params
 */
export function getTemplateId(params) {
  let token = localStorage.getItem('application:au');
  return request({
    url: '/api/billing/payment_notice/template/list?token=' + token,
    method: 'get',
    params,
  });
}

/**
 * 实时数据，在线刷新获取列表
 * @param params
 */
export function getGenerat(params) {
  let token = localStorage.getItem('application:au');
  return request({
    url: '/api/billing/payment_notice/fetch?token=' + token + '&_t=',
    method: 'post',
    data: params,
  });
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "quick-link" }, [
    _c("div", { staticClass: "quick-title" }, [_vm._v("快捷链接")]),
    _c(
      "div",
      { staticClass: "quick-border" },
      _vm._l(_vm.data, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "list",
            on: {
              click: function ($event) {
                return _vm.golink(item.linkUrl, item.developUrl, item.sid)
              },
              mouseleave: function ($event) {
                return _vm.changImg(index)
              },
              mouseenter: function ($event) {
                return _vm.changImg(index)
              },
            },
          },
          [
            _vm._v("\n      " + _vm._s(item.title) + "\n      "),
            item.urlType
              ? _c("img", { attrs: { src: item.iconPathOff, alt: "" } })
              : _c("img", { attrs: { src: item.iconPathOn, alt: "" } }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }